export const $themeColors = {}

export const $themeBreakpoints = {}

export const $themeConfig = {
  app: {
    appName: 'MyBestquimica',
    appLogoImage: require('@/assets/images/logo/logo.png'),
  },
  layout: {
    isRTL: false,
    skin: 'bordered',
    routerTransition: 'none',
    type: 'vertical',
    contentWidth: 'full',
    menu: {
      hidden: false,
      isCollapsed: false, // Nao alterar este valor devido que nao esta ainda previsto no template base
    },
    navbar: {
      type: 'static',
      backgroundColor: '',
    },
    footer: {
      type: 'hidden',
    },
    customizer: false,
    enableScrollToTop: true,
  },
}
