const routeOrderCreate = () => import(/* webpackChunkName: "page-order-create" */ '@/views/modules/orders/add.vue')
const routeOrderList = () => import(/* webpackChunkName: "page-order-list" */ '@/views/modules/orders/listview/index.vue')

export default [
  {
    path: '/sales/orders/new',
    name: 'sales-orders-new',
    component: routeOrderCreate,
    meta: {
      resource: 'sales',
      action: 'new',
    },
  },
  {
    path: '/sales/orders/list',
    name: 'sales-orders-list',
    component: routeOrderList,
    meta: {
      resource: 'sales',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'Encomendas',
        },
        {
          text: 'Listagem',
          active: true,
        },
      ],
    },
  },
]
