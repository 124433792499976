const routeProductsListview = () => import(/* webpackChunkName: "products-listview" */ '@/views/modules/products/listview/index.vue')

export default [
  {
    path: '/products/list',
    name: 'products-listview',
    component: routeProductsListview,
    meta: {
      resource: 'products',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'ecommerce-application email-application',
      breadcrumbNavbar: [
        {
          text: 'Produtos',
        },
        {
          text: 'Todos',
          active: true,
        },
      ],
    },
  },
]
