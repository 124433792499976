import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import store from '@/store'
import compareVersions from 'compare-versions'
import checkNested from 'check-nested'
import { SplashScreen } from '@capacitor/splash-screen'

import routerOther from './modules/other'
import routerGeral from './modules/geral'
import routerAuth from './modules/auth'
import routerProducts from './modules/products'
import routerCRM from './modules/crm'
import routerSales from './modules/sales'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...routerOther,
    ...routerGeral,
    ...routerAuth,
    ...routerProducts,
    ...routerCRM,
    ...routerSales,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  // Cancela todos os pedidos ajax pendentes
  store.dispatch('request/cancelTokensPending')

  Promise.all([store.dispatch('auth/checkAuth')])
    .then(() => {
      if (typeof to.meta.renderBreadcrumb === 'function') {
        const changeTo = to
        changeTo.meta.breadcrumbNavbar = to.meta.renderBreadcrumb(to, store)
      }

      // Verifica se a versao e permitida
      if (checkNested(store.state.auth.configServer, 'updateVersionStore.minVersionRequired.version') && (store.getters['capacitor/isNative'] === true)) {
        if (compareVersions(process.env.VUE_APP_VERSION, store.state.auth.configServer.updateVersionStore.minVersionRequired.version) === -1) {
          if (to.name !== 'update-version') {
            next({ name: 'update-version' })
          } else {
            next()
          }
        } else if (to.name === 'update-version') {
          next({ name: 'auth-login' })
        }
      }

      if (to.meta.resource === 'PageGeral') {
        if (to.name === 'under-maintenance') {
          if ((Object.keys(store.state.auth.configServer).length > 0) && (store.state.auth.configServer.manutencao.status === false)) {
            next({ name: 'auth-login' })
          } else {
            next()
          }
        } else {
          next()
        }
      } else if (Object.keys(store.state.auth.configServer).length === 0) {
        next({ name: 'coming-soon' })
      } else if (store.state.auth.configServer.manutencao.status === true) {
        next({ name: 'under-maintenance' })
      } else if (to.meta.resource === 'Auth') {
        if (store.state.auth.isAuthenticated === true) {
          let oParamsRedirectDefault = { name: 'sales-orders-new' }
          if ((localStorage.getItem('router-default') !== null) && (localStorage.getItem('router-default') !== '')) {
            const routerRedirectParams = JSON.parse(localStorage.getItem('router-default')) || {}

            if ('name' in routerRedirectParams) {
              oParamsRedirectDefault = routerRedirectParams
            }

            if ('path' in routerRedirectParams) {
              oParamsRedirectDefault = routerRedirectParams
            }

            localStorage.removeItem('router-default')
          }

          next(oParamsRedirectDefault)
        } else {
          next()
        }
      } else if (store.state.auth.isAuthenticated === true) {
        if (!canNavigate(to)) {
          next({ name: 'not-authorized' })
        } else {
          next()
        }
      } else {
        next({ name: 'auth-login' })
      }
    })
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
    setTimeout(() => {
      SplashScreen.hide()
    }, 5000)
  }
})

export default router
