import ability from '@/libs/acl/ability'
import { initialAbility } from '@/libs/acl/config'
import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'

export default {
  namespaced: true,
  state: {
    errors: null,
    isAuthenticated: !!api.getToken(),
    configServer: {},
    user: {},
    userCurrentSession: {},
    locales: [
      {
        locale: 'pt',
        img: require('@/assets/images/flags/pt.png'),
        name: i18n.t('Português'),
      },
      {
        locale: 'es',
        img: require('@/assets/images/flags/es.png'),
        name: i18n.t('Espanhol'),
      },
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: i18n.t('Inglês'),
      },
    ],
  },
  getters: {
    currentUser: state => state.user,
    isAuthenticated: state => state.isAuthenticated,
    getConfigServer: state => state.configServer,
    currentUserSession: state => state.userCurrentSession,
    locales: state => state.locales,
  },
  mutations: {
    setError(state, error) {
      state.errors = error
    },
    setUser(state, data) {
      state.isAuthenticated = true
      state.user = data.infoUser
      ability.update(data.infoUser.ability)

      api.setToken(data.accessToken)
      api.setRefreshToken(data.refreshToken)
    },
    refreshInfoUser(state, data) {
      state.isAuthenticated = true
      state.user = data.infoUser
      ability.update(data.infoUser.ability)
    },
    logOut(state) {
      state.isAuthenticated = false
      state.user = {}
      ability.update(initialAbility)
      api.destroyToken()

      setTimeout(() => {
        localStorage.clear()
      }, 800)
    },
    setConfigServer(state, config) {
      state.configServer = config || {}
    },
    refreshConfigServer(state) {
      api.refreshConfigs(state.configServer)
      apiConfig.url_base_api = state.configServer.urlAPP
    },
    setUserSession(state, data) {
      const { infoUser } = data
      if (infoUser) {
        const oUserCurrentSession = {
          name: infoUser.name || '',
          email: infoUser.email || '',
        }
        localStorage.setItem('user', JSON.stringify(oUserCurrentSession))
      } else {
        localStorage.removeItem('user')
      }

      state.userCurrentSession = infoUser || {}
    },
    restoreUserSession(state) {
      let infoUser = null
      try {
        infoUser = JSON.parse(localStorage.getItem('user'))
      } catch {
        //
      }
      state.userCurrentSession = infoUser || null
    },
  },
  actions: {

    async getConfigServer(context) {
      await api.post(`${apiConfig.url_base_api}index.php?dispatch=bestquimica_api.getConfig`).then(({ data }) => {
        const { config } = data
        context.commit('restoreUserSession')
        context.commit('setConfigServer', config)
        context.commit('refreshConfigServer')
      }).catch(() => {
        context.commit('restoreUserSession')
        context.commit('setConfigServer', {})
        context.commit('refreshConfigServer')
      })
    },

    async getInfoUserAuth({ state, commit, rootGetters }) {
      const idDevice = rootGetters['capacitor/idDevice']

      const formData = new FormData()
      formData.append('uuid', idDevice || '')
      formData.append('versionApp', process.env.VUE_APP_VERSION || '')

      const sTemplateAppCustomByUser = localStorage.getItem('template-app-custom-user')
      const sTemplateApp = localStorage.getItem('template-app')

      if (sTemplateAppCustomByUser !== null) {
        formData.append('skin', sTemplateApp || '')
      }

      await api.post(`${state.configServer.urlAPP}index.php?dispatch=bestquimica_api.getInfoUser`, formData).then(({ data }) => {
        const { infoUser, config } = data
        if (infoUser) {
          commit('setConfigServer', config)
          commit('refreshConfigServer')
          commit('refreshInfoUser', data)
        } else {
          commit('logOut')
        }
      }).catch(() => {
        commit('logOut')
      })
    },

    login(context, params) {
      return new Promise((resolve, reject) => {
        api.post(`${context.state.configServer.urlAPP}index.php?dispatch=bestquimica_api.login`, params)
          .then(({ data }) => {
            if (data.error === 1) {
              reject(data)
            } else {
              resolve(data)
            }
          })
          .catch(({ response }) => {
            context.commit('setError', response.data.errors)
          })
      })
    },

    authLockscreen(context, params) {
      return new Promise((resolve, reject) => {
        api.post(`${context.state.configServer.urlAPP}index.php?dispatch=bestquimica_api.login`, params)
          .then(({ data }) => {
            if (data.error === 1) {
              reject(data)
            } else {
              resolve(data)
            }
          })
          .catch(({ response }) => {
            context.commit('setError', response.data.errors)
          })
      })
    },

    loginUser(context, data) {
      context.commit('setUser', data)
      context.commit('setUserSession', data)
      context.commit('refreshConfigServer')
    },

    async logout(context) {
      await context.dispatch('request/cancelTokensPending', null, { root: true })
      context.commit('logOut')
    },

    async checkAuth(context) {
      if (!!api.getToken() && !!context.state.userCurrentSession) {
        await context.dispatch('getInfoUserAuth')
      } else {
        context.commit('logOut')
        context.commit('setUserSession', {})
      }
    },

    forgotPassword(context, params) {
      return new Promise((resolve, reject) => {
        api.post(`${context.state.configServer.urlAPP}users/forgotPasswordRequestMobile`, params)
          .then(({ data }) => {
            if (data.error === 1) {
              reject(data)
            } else {
              resolve(data)
            }
          })
          .catch(({ response }) => {
            context.commit('setError', response.data.errors)
          })
      })
    },

    getChangePasswordValid(context, params) {
      return new Promise(resolve => {
        api.get(`${context.state.configServer.urlAPP}users/forgotPassword/${params.token}`)
          .then(({ data }) => {
            if ((data.data.changePasswordRecover === 1) && (data.data.swID !== '')) {
              resolve(data.data.swID)
            } else {
              resolve('')
            }
          })
          .catch(() => {
            resolve('')
          })
      })
    },

    changeForgotPasswordRequest(context, params) {
      return new Promise((resolve, reject) => {
        api.post(`${context.state.configServer.urlAPP}users/changeForgotPasswordRequest`, params)
          .then(({ data }) => {
            if (data.error === 1) {
              reject(data)
            } else {
              resolve(data)
            }
          })
          .catch(({ response }) => {
            context.commit('setError', response.data.errors)
          })
      })
    },

    async getPreferenceByUserAuth() {
      return new Promise(resolve => {
        const preferenceUserApp = { locale: '', skin: '' }
        resolve(preferenceUserApp)
      })
    },

  },
}
