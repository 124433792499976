import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import authModule from './modules/auth'
import requestModule from './modules/requests'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import capacitor from './capacitor'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth: authModule,
    request: requestModule,
    capacitor,
  },
  strict: process.env.DEV,
})
