const routeCrmClientsNew = () => import(/* webpackChunkName: "crm-clients-form" */ '@/views/modules/crm/clients/form/index.vue')
const routeCrmClientsListview = () => import(/* webpackChunkName: "crm-clients-listview" */ '@/views/modules/crm/clients/listview/index.vue')

export default [
  {
    path: '/crm/clients/new',
    name: 'crm-clients-new',
    component: routeCrmClientsNew,
    meta: {
      resource: 'crm',
      action: 'new',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'CRM',
        },
        {
          text: 'Clientes',
          to: '/crm/clients/list',
        },
        {
          text: 'Novo cliente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/crm/clients/list',
    name: 'crm-clients-list',
    component: routeCrmClientsListview,
    meta: {
      resource: 'crm',
      action: 'list',
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      breadcrumbNavbar: [
        {
          text: 'CRM',
        },
        {
          text: 'Clientes',
          active: true,
        },
      ],
    },
  },
]
